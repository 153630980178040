import * as t from 'io-ts'
import { ActionBase, ActionDisplay } from './ActionBase'
import { CardId } from '../Card'

export const CreateCardAction = t.intersection([
  ActionBase,
  t.type({
    type: t.literal('createCard'),
    data: t.type({
      card: t.intersection([CardId, t.type({ name: t.string })]),
    }),
  }),
])
export type CreateCardAction = t.TypeOf<typeof CreateCardAction>

export function createCardActionDisplay(
  action: CreateCardAction
): ActionDisplay {
  return {
    predicate: 'created this card with title',
    value: action.data.card.name,
    valueField: 'title',
  }
}

export function createCardActionRestore(action: CreateCardAction) {
  return {
    field: 'name' as 'name',
    value: action.data.card.name,
  }
}
