import React from 'react'
import styles from './Onboarding.module.css'
import screenshot from './Onboarding-screenshot.png'
import { Authorize } from './Authorize'
import { useSetSentryUser } from '../hooks/useSetSentryUser'
import { TrelloPowerUp } from '../TrelloPowerUp'

export const Onboarding: React.FC = () => {
  useSetSentryUser(TrelloPowerUp.iframe())
  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <img
          src={screenshot}
          width={316}
          alt="How To Use: Open a card back and, in the Power-Ups section, select 'Restore'"
        />
      </div>
      <div className={styles.right}>
        <h1>Get your old card titles and descriptions back</h1>
        <p>
          <em>Undo for Trello</em> allows you to access and restore the whole
          history of your cards. Though we are in beta, you can use it today to
          restore old card titles and descriptions.
        </p>
        <Authorize />
        {/* <p>We need your permission to access your card history.</p>
        <button id="auth-btn" type="submit" className="mod-primary">
          Authorize
        </button> */}
      </div>
    </div>
  )
}
