import React, { useEffect } from 'react'
import { TrelloPowerUp } from '../TrelloPowerUp'

export const Connector: React.FC = () => {
  useEffect(() => {
    const capabilities = {
      'on-enable': async (t: any) => {
        if (!(await capabilities['authorization-status'](t)).authorized) {
          return t.modal({
            title: 'Undo for Trello ᴮᴱᵀᴬ',
            url: '/onboarding',
            accentColor: 'blue',
            height: 400,
            fullscreen: false,
          })
        }
      },
      'card-buttons': () => [
        {
          icon: window.location.origin + '/_/icon',
          text: 'Restore',
          callback: async (t: any) => {
            if ((await capabilities['authorization-status'](t)).authorized) {
              t.popup({
                title: 'Restore — Undo for Trello ᴮᴱᵀᴬ',
                url: '/actions',
                args: { args: { cardId: t.getContext().card } },
                height: 14,
              })
            } else {
              await capabilities['show-authorization'](t)
            }
          },
        },
      ],
      'authorization-status': async (t: any) => {
        const token = await t.get('member', 'private', 'token')
        return { authorized: !!token }
      },
      'show-authorization': async (t: any) => {
        t.popup({
          title: 'Authorize',
          url: '/authorize',
          height: 14,
        })
      },
    }
    /* eslint-disable-next-line no-undef */
    TrelloPowerUp.initialize(capabilities)
  }, [])
  return (
    <div>
      This is the HTML connector for the Power-Up. It will be loaded into a
      hidden iframe when your Power-Up is enabled.
    </div>
  )
}
