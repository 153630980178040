import React from 'react'
import styles from './Avatar.module.css'
import { Member } from '../models/Member'

interface Props {
  member: Member
}

export const Avatar: React.FC<Props> = ({ member }) => {
  return (
    <div className={styles.root}>
      {member.avatarUrl ? (
        <img
          className={styles.avatar}
          alt={`${member.fullName} (${member.username})`}
          title={`${member.fullName} (${member.username})`}
          width={30}
          height={30}
          src={`${member.avatarUrl}/30.png`}
          srcSet={`${member.avatarUrl}/30.png 1x, ${
            member.avatarUrl
          }/50.png 2x`}
        />
      ) : (
        <div className={styles.memberInitials}>{member.initials}</div>
      )}
    </div>
  )
}
