import React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { Avatar } from './Avatar'
import styles from './ActionC.module.css'
import { TrelloPowerUp } from '../TrelloPowerUp'
import { Action, actionDisplay } from '../models/Action'
import { truncate } from 'underscore.string'

interface Props {
  action: Action
  primary?: boolean
}

export const ActionC: React.FC<Props> = ({ action, primary = false }) => {
  const display = actionDisplay(action)
  if (!display) {
    return null
  }
  const { predicate, value } = display
  return (
    <div className={styles.root}>
      <div className={styles.creator}>
        <Avatar member={action.memberCreator} />
      </div>
      <div className={styles.desc}>
        <strong>{action.memberCreator.fullName}</strong> {predicate}{' '}
        {value !== undefined && <em>{truncate(value.toString(), 80)}</em>}
      </div>
      <div className={styles.date}>{moment(action.date).fromNow()}</div>
      <div className={styles.operations}>
        <button
          className={classNames({
            'mod-primary': primary,
          })}
          onClick={(ev) => {
            ev.stopPropagation()
            TrelloPowerUp.iframe().popup({
              title: 'Confirm Restore',
              url: '/actions/restore',
              args: { args: { action } },
              height: 560,
            })
          }}
        >
          Restore
        </button>
        {/*
        <button
          className={classNames({ 'quiet-button': !primary })}
          onClick={(ev) => {
            ev.stopPropagation()
            TrelloPowerUp.iframe().popup({
              title: 'Confirm Undo',
              url: '/actions/undo',
              args: { args: { action } },
              height: 280,
            })
          }}
        >
          Undo
        </button>
        */}
      </div>
    </div>
  )
}
