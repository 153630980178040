import { stringify } from 'query-string'
import React, { useEffect } from 'react'
import { useSetSentryUser } from '../hooks/useSetSentryUser'
import { useSizeTo } from '../hooks/useSizeTo'
import { TRELLO_API_KEY } from '../Trello'
import { TrelloPowerUp } from '../TrelloPowerUp'

export const Authorize: React.FC = () => {
  useSizeTo()
  useSetSentryUser(TrelloPowerUp.iframe())

  const trelloAuthUrl =
    'https://trello.com/1/authorize?' +
    stringify({
      expiration: 'never',
      name: 'Undo for Trello',
      scope: 'read,write',
      key: TRELLO_API_KEY,
      callback_method: 'fragment',
      return_url: window.location.origin + '/authorize',
    })
  async function authorize() {
    const t = TrelloPowerUp.iframe()
    const token = await t.authorize(trelloAuthUrl, {
      height: 680,
      width: 580,
    })
    await t.set('member', 'private', 'token', token)
    t.closePopup() // for authorize popup
    t.closeModal() // for onboarding modal
  }

  const prefix = '#token='
  let token: string | undefined = undefined
  if (window.location.hash.indexOf(prefix) === 0) {
    token = window.location.hash.substr(prefix.length)
  }

  // When this page is opened again with a succesful authorization token, pass
  // the token back to the initial authorization page.
  useEffect(() => {
    if (token) {
      if (window.opener) {
        window.opener.authorize(token)
      } else {
        localStorage.setItem('token', token)
      }
      setTimeout(() => window.close(), 1000)
    }
  }, [token])

  if (token) {
    return null
  }

  return (
    <div>
      <p>We need your permission to access your card history.</p>
      <button
        id="auth-btn"
        type="submit"
        className="mod-primary"
        onClick={authorize}
      >
        Authorize
      </button>
    </div>
  )
}
