import React from 'react'
import { Route } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { TrelloPowerUp } from './TrelloPowerUp'
import { Connector } from './pages/Connector'
import { Authorize } from './pages/Authorize'
import { ActionList } from './pages/ActionList'
import { ActionDetail } from './pages/ActionDetail'
import { ActionUndo } from './pages/ActionUndo'
import { ActionRestore } from './pages/ActionRestore'
import { Onboarding } from './pages/Onboarding'

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <div id="content">
        <Route path="/" exact component={Connector} />
        <Route path="/onboarding" component={Onboarding} />
        <Route path="/authorize" component={Authorize} />
        <Route
          path="/actions"
          exact
          render={() => <ActionList {...TrelloPowerUp.iframe().arg('args')} />}
        />
        <Route
          path="/actions/detail"
          render={() => (
            <ActionDetail {...TrelloPowerUp.iframe().arg('args')} />
          )}
        />
        <Route
          path="/actions/undo"
          render={() => <ActionUndo {...TrelloPowerUp.iframe().arg('args')} />}
        />
        <Route
          path="/actions/restore"
          render={() => (
            <ActionRestore {...TrelloPowerUp.iframe().arg('args')} />
          )}
        />
      </div>
    </BrowserRouter>
  )
}

export default App
