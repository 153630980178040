import { useState, useEffect } from 'react'
import { Trello } from '../Trello'

export function useTrello<R>(
  path: string | string[],
  params: any
): R | undefined
export function useTrello<R>(
  path: string | string[],
  params: any,
  initialValue: R
): R
export function useTrello<R>(
  path: string | string[],
  params: any,
  initialValue?: R
) {
  const [response, setResponse] = useState(initialValue)
  useEffect(() => {
    async function fetch() {
      setResponse(await Trello.get(path, params))
    }
    // TODO: There is a race condition here. It's possible for a GET to finish
    // after a second GET so that data will not correspond to the appropriate
    // card.
    fetch()

    // https://github.com/facebook/react/issues/14476#issuecomment-471199055
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(path), JSON.stringify(params)])
  return response
}
