import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'
// import * as serviceWorker from './serviceWorker'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  integrations: [
    new BrowserTracing(),
    new Sentry.Replay({
      maskAllText: true,
    }),
  ],
  tracesSampleRate: 0.01,
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
})

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls. Learn
// more about service workers:
// https://create-react-app.dev/docs/making-a-progressive-web-app/

// serviceWorker.unregister()

// Even the above call to serviceWorker.unregister() was causing errors in
// Firefox (UNDO-FOR-TRELLO-8S). Possibly explained by
// https://stackoverflow.com/q/49539306/. Since we don't use service workers at
// all and never have, let's just comment it out.
