import { useEffect } from 'react'
import * as Sentry from '@sentry/browser'

export function useSetSentryUser(t: TrelloPowerUp.Client) {
  // TODO: This method is suboptimal because it causes errors that happen
  // synchronously (e.g. in the first render) to not have a Sentry user
  // associated.
  //
  // Depending on how fast `t.member()` is supposed to be, we can either
  // halt further processing here, or pass the user info in the URL, retrieved
  // at the capability level (before anything is clicked, in the happy
  // "card-buttons" path) and passed down to sub-popups, or as a cookie.
  useEffect(() => {
    ;(async () => {
      const member = await t.member('id', 'username', 'fullName')
      Sentry.setUser(member)
    })()

    return () => Sentry.configureScope((scope) => scope.setUser(null))
  })
}
