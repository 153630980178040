import * as t from 'io-ts'

export const CardId = t.type({
  id: t.string,
})
export type CardId = t.TypeOf<typeof CardId>

export const CardFields = t.type({
  name: t.string,
  desc: t.string,
})
export type CardFields = t.TypeOf<typeof CardFields>

export const Card = t.intersection([CardId, CardFields])
export type Card = t.TypeOf<typeof Card>

export function cardFieldDisplay(field: keyof CardFields) {
  if (field === 'name') {
    return 'title'
  } else if (field === 'desc') {
    return 'description'
  } else {
    return field
  }
}
