import * as t from 'io-ts'
import {
  CreateCardAction,
  createCardActionDisplay,
  createCardActionRestore,
} from './CreateCardAction'
import {
  UpdateCardAction,
  updateCardActionDisplay,
  updateCardActionRestore,
} from './UpdateCardAction'
import { ActionDisplay, ActionRestore } from './ActionBase'

export const Action = t.taggedUnion('type', [
  CreateCardAction,
  UpdateCardAction,
])
export type Action = t.TypeOf<typeof Action>

export function actionDisplay(action: Action): ActionDisplay {
  if (action.type === 'createCard') {
    return createCardActionDisplay(action)
  } else if (action.type === 'updateCard') {
    return updateCardActionDisplay(action)
  } else {
    throw new Error('unreachable')
  }
}

export function actionRestore(action: Action): ActionRestore {
  if (action.type === 'createCard') {
    return createCardActionRestore(action)
  } else if (action.type === 'updateCard') {
    return updateCardActionRestore(action)
  } else {
    throw new Error('unreachable')
  }
}
