import * as t from 'io-ts'
import { ActionBase, ActionDisplay } from './ActionBase'
import { CardId, CardFields, cardFieldDisplay } from '../Card'

export const UpdateCardAction = t.intersection([
  ActionBase,
  t.type({
    type: t.literal('updateCard'),
    data: t.type({
      card: t.intersection([
        CardId,
        t.type({ name: t.string }),
        t.partial(CardFields.props),
      ]),
      old: t.partial(CardFields.props),
    }),
  }),
])
export type UpdateCardAction = t.TypeOf<typeof UpdateCardAction>

export function updateCardActionField(action: UpdateCardAction) {
  const fields = Object.keys(action.data.old)
  if (fields.length !== 1) {
    console.warn('more than one field in updateCard action:', fields)
  }
  return fields[0] as keyof CardFields
}

export function updateCardActionDisplay(
  action: UpdateCardAction
): ActionDisplay {
  const field = updateCardActionField(action)
  const fieldDisplay = cardFieldDisplay(field)
  return {
    predicate: `changed the ${fieldDisplay} of this card to`,
    valueField: fieldDisplay,
    value: action.data.card[field],
  }
}

export function updateCardActionRestore(action: UpdateCardAction) {
  const field = updateCardActionField(action)
  return {
    field,
    value: action.data.card[field],
  }
}
