import React from 'react'
import { ActionC } from '../components/ActionC'
import styles from './ActionDetail.module.css'
import { Action, actionDisplay } from '../models/Action'
import { capitalize } from 'underscore.string'
import { useSizeTo } from '../hooks/useSizeTo'

interface Props {
  action: Action
}

export const ActionDetail: React.FC<Props> = ({ action }) => {
  useSizeTo()
  const display = actionDisplay(action)
  return (
    <div className={styles.root}>
      <ActionC action={action} primary />
      {/*
      <h4>Title Before</h4>
      <div>{action.data.old.name}</div>
      */}
      <h4>{capitalize(display.valueField)}</h4>
      <div>{display.value}</div>
    </div>
  )
}
