import React from 'react'
import styles from './ActionUndo.module.css'
import { Trello } from '../Trello'
import { TrelloPowerUp } from '../TrelloPowerUp'
import { useTrello } from '../hooks/useTrello'
import { useSizeTo } from '../hooks/useSizeTo'
import { useSetSentryUser } from '../hooks/useSetSentryUser'

interface Props {
  action: any
}

export const ActionUndo: React.FC<Props> = ({ action }) => {
  useSizeTo()
  useSetSentryUser(TrelloPowerUp.iframe())

  const card: any = useTrello(['cards', action.data.card.id], {
    fields: ['name'],
  })
  if (!card) {
    return null
  }

  const current = card.name
  const afterUndo = action.data.old.name

  async function undo() {
    await Trello.put(['cards', action.data.card.id], { name: afterUndo })
    TrelloPowerUp.iframe().closePopup()
  }

  return (
    <div>
      <p>
        Do you want to change the <strong>title</strong> of this card?
      </p>
      <h4>Current Title</h4>
      <div>{current}</div>
      <h4>Title After Undo</h4>
      <div>{afterUndo}</div>
      <div className={styles.controls}>
        <button className="mod-primary" onClick={undo}>
          Undo
        </button>
      </div>
    </div>
  )
}
