import React, { AnchorHTMLAttributes } from 'react'
import { TrelloPowerUp } from '../TrelloPowerUp'

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  title: string
  height?: number
  to: string
  args: any
}

export const PopupLink: React.FC<Props> = ({
  title,
  height,
  to,
  args,
  children,
  ...props
}) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href="#"
      onClick={(ev) => {
        ev.preventDefault()
        TrelloPowerUp.iframe().popup({
          title,
          height,
          url: to,
          args: { args },
        })
      }}
      {...props}
    >
      {children}
    </a>
  )
}
