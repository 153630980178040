import React from 'react'
import { capitalize } from 'underscore.string'
import styles from './ActionRestore.module.css'
import { Trello } from '../Trello'
import { TrelloPowerUp } from '../TrelloPowerUp'
import { useTrello } from '../hooks/useTrello'
import { Action, actionRestore } from '../models/Action'
import { cardFieldDisplay } from '../models/Card'
import { useSizeTo } from '../hooks/useSizeTo'
import { useSetSentryUser } from '../hooks/useSetSentryUser'

interface Props {
  action: Action
}

export const ActionRestore: React.FC<Props> = ({ action }) => {
  useSizeTo()
  useSetSentryUser(TrelloPowerUp.iframe())

  const { field, value } = actionRestore(action)
  const card: any = useTrello(['cards', action.data.card.id], {
    fields: [field],
  })
  if (!card) {
    return null
  }
  const fieldDisplay = cardFieldDisplay(field)

  const currentValue = card[field]

  async function restore() {
    await Trello.put(['cards', action.data.card.id], { [field]: value })
    TrelloPowerUp.iframe().closePopup()
  }

  return (
    <div>
      <p>
        Do you want to change the <strong>{fieldDisplay}</strong> of this card?
      </p>
      <h4>Current {capitalize(fieldDisplay)}</h4>
      <div>{currentValue}</div>
      <h4>{capitalize(fieldDisplay)} After Restore</h4>
      <div>{value}</div>
      <div className={styles.controls}>
        <button className="mod-primary" onClick={restore}>
          Restore
        </button>
      </div>
    </div>
  )
}
