import * as t from 'io-ts'

export const Member = t.type({
  id: t.string,
  fullName: t.string,
  username: t.string,
  avatarUrl: t.union([t.string, t.null]),
  initials: t.string,
})
export type Member = t.TypeOf<typeof Member>
