import { stringify } from 'query-string'
import { TrelloPowerUp } from './TrelloPowerUp'

export const TRELLO_API_KEY = 'fc4b4b402fa5a4b76ba429ad76747793'

const fetcher = (method: string) => async (
  path: string | string[],
  params: any
) => {
  if (typeof path !== 'string') {
    path = path.join('/')
  }
  params = {
    ...params,
    key: TRELLO_API_KEY,
    // eslint-disable-next-line no-undef
    token: await TrelloPowerUp.iframe().get('member', 'private', 'token'),
  }
  const query = stringify(params, {
    arrayFormat: 'comma',
  })
  const request = new Request(`https://api.trello.com/1/${path}?${query}`, {
    method: method,
  })
  const response = await fetch(request)
  return response.json()
}

export const Trello = {
  get: fetcher('GET'),
  put: fetcher('PUT'),
  post: fetcher('POST'),
  delete: fetcher('DELETE'),
}
