import * as Sentry from '@sentry/browser'
import { lefts, rights } from 'fp-ts/lib/Array'
import React, { useEffect, useMemo, useState } from 'react'
import { ActionC } from '../components/ActionC'
import { PopupLink } from '../components/PopupLink'
import { useSetSentryUser } from '../hooks/useSetSentryUser'
import { useSizeTo } from '../hooks/useSizeTo'
import { useTrello } from '../hooks/useTrello'
import { Action } from '../models/Action'
import { updateCardActionField } from '../models/Action/UpdateCardAction'
import { TrelloPowerUp } from '../TrelloPowerUp'
import styles from './ActionList.module.css'

interface Props {
  cardId: string
}

export const ActionList: React.FC<Props> = ({ cardId }) => {
  useSizeTo()
  useSetSentryUser(TrelloPowerUp.iframe())

  const [hoverActionId, setHoverActionId] = useState<String>()
  const rawActions: any[] | undefined = useTrello(
    ['cards', cardId, 'actions'],
    {
      filter: 'all',
    }
  )

  const decodedActions = useMemo(
    () =>
      rawActions &&
      rawActions
        .filter(
          (action) =>
            // List of action types that we ignore (blacklist) because we know
            // they don't affect the card name or description.
            ![
              'addAttachmentToCard',
              'addChecklistToCard',
              'addMemberToCard',
              'commentCard',
              // 'convertToCardFromCheckItem',
              // 'copyCard',
              // 'copyCommentCard',
              // 'createCard', ✅
              'deleteCard',
              // 'emailCard',
              'moveCardFromBoard',
              'moveCardToBoard',
              'removeChecklistFromCard',
              'removeMemberFromCard',
              // 'updateCard', ✅
              'updateCheckItemStateOnCard',
            ].includes(action.type)
        )
        .map((action) => Action.decode(action)),
    [rawActions]
  )

  useEffect(() => {
    if (!decodedActions) {
      return
    }
    for (const errors of lefts(decodedActions)) {
      console.warn(JSON.stringify(errors, null, 2))
      Sentry.captureException(
        new Error(
          `An action of type ${
            (errors[0].context[0].actual as any).type
          } could not be parsed`
        )
      )
    }
  }, [decodedActions])

  const actions = decodedActions && rights(decodedActions)
  if (!actions) {
    return null
  }

  if (actions.length === 0) {
    return <div className="empty-list">No history</div>
  }
  return (
    <ol className={styles.root}>
      {actions.map((action) => {
        if (
          (action.type === 'createCard' || action.type === 'updateCard') &&
          action.data.card.name !== undefined
        ) {
          if (
            action.type === 'updateCard' &&
            ['name', 'desc'].indexOf(updateCardActionField(action)) === -1
          ) {
            return null
          }
          return (
            <li key={action.id}>
              <PopupLink
                title="Confirm Restore"
                to="/actions/restore"
                className={styles.action}
                args={{ action }}
                height={560}
                onMouseOver={() => setHoverActionId(action.id)}
                onMouseOut={() => setHoverActionId(undefined)}
              >
                <ActionC
                  action={action}
                  primary={hoverActionId === action.id}
                />
              </PopupLink>
            </li>
          )
        }
        return null
      })}
    </ol>
  )
}

/*
  const rawActions = [
    {
      id: '5d10dfc3fa2bae478440c038',
      idMemberCreator: '58530f69bd582fbf01f4a8fc',
      data: {
        list: {
          name: 'Test List',
          id: '5cf580c4dc88351f2aa4609e',
        },
        board: {
          shortLink: 'sYwUI5hU',
          name: 'Power-Up Review: Undo For Trello',
          id: '5cf580c4dc88351f2aa4609b',
        },
        card: {
          shortLink: 'IUV9ZFcF',
          idShort: 5,
          name: 'Buy Hot Dog',
          id: '5cf580c4dc88351f2aa460cd',
          desc: 'wdawadddddd\n\n\nawdwad',
        },
        old: {
          desc: 'wdawadddddd',
        },
      },
      type: 'updateCard',
      date: '2019-06-24T14:35:47.968Z',
      limits: {},
      memberCreator: {
        id: '58530f69bd582fbf01f4a8fc',
        avatarHash: null,
        avatarUrl: null,
        fullName: 'bentleytest1',
        idMemberReferrer: null,
        initials: 'B',
        nonPublic: {},
        nonPublicAvailable: true,
        username: 'bentleytest1',
      },
    },
  ]
  //*/
