import * as t from 'io-ts'
import { Member } from '../Member'
import { CardFields } from '../Card'

export const ActionBase = t.type({
  /** The ID of the action */
  id: t.string,
  /** Relevant information regarding the action */
  // data: t.object,
  /** When the action occurred */
  date: t.string,
  /** The ID of the member who caused the action */
  idMemberCreator: t.string,
  /** The type of the action */
  // type: t.string,
  memberCreator: Member,
})
type ActionBase = t.TypeOf<typeof ActionBase>

export interface ActionDisplay {
  predicate: string
  value: any | undefined
  valueField: string
}

export interface ActionRestore {
  field: keyof CardFields
  value: any
}
